* {
  margin: 0;
  padding: 0;
}

.content {
  margin-left: 250px;
  margin-top: 90px;
  padding-right: 2em;
}
