.login {
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  display: flex;
  height: 90vh;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em 0 2em 4em;

  &__logo {
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 59%;

    &__title {
      font-weight: lighter;
      color: $color-gray;
    }

    &__description {
      font-size: 3.5em;
      font-weight: bold;

      span {
        color: $color-pink
      }

      margin-bottom: 0.5em;
    }

    &__button {
      width: 10em;
    }
  }

  &__footer {
    display: flex;

    &__logo {
      padding-right: 1em;
    }

    &__text {

      &-first {
        color: $color-gray;
      }
    }
  }
}