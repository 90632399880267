/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 1000;

  &__dialog {
    position: relative;
    background: $color-white;
    padding: 1em;
    top: 35%;
    left: 40%;
    border-radius: 8px;
    width: 400px;
    max-width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal__close {
      background-color: $color-light-gray;
      padding: 0 0.25em 0 0.25em;
      font-size: 1.5em;

      &:hover {
        background-color: darken($color-light-gray, 1%);
        cursor: pointer;
      }
    }
  }

  &__body {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;

  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}