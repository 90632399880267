// Colors
$color-black: #000;
$color-white: #fff;
$color-active: #2370c9;
$color-gray: #9DA2B2;
$color-pink: #FF2083;
$color-purple: #7d32d7;
$color-light-gray: #F8F9FC;
$color-darkblue: #00153F;
$border-color: #DFDFDF;
$color-success: #00AD83;
$color-error: #fd5c63;

// Status colors
$color-approved: #00AD83;
$color-rejected: #fd5c63;
$color-updated: #FF2083;
$color-cancelled: #9DA2B2;
$color-requested: #000000;


// Fonts
$font-family-primary: 'Arial', sans-serif;
