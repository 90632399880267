.employee {
  &__card {
    border: $border-color solid 1px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;

    &__image {
      border-radius: 50%;
      object-fit: cover;
      margin: 0 0 0.7em 0;
    }

    &__name {
      margin: 0 0 0.7em 0;
      color: $color-darkblue;
      text-align: center;

      p {
        margin-top: 0.2em;
        font-size: small;
      }
    }

    &__dayLeft {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background-color: $color-darkblue;
      border-radius: 4px;
      color: white;
      padding: 0.5em;

      h2 {
        padding: 0;
        margin: 0;
      }

      p {
        margin: 0.5em;
        font-weight: lighter;
        color: $color-gray;
        font-size: small;
      }
    }
  }
}

