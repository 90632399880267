.topBar {
  position: fixed;
  background-color: $color-white;
  top: 0;
  left: 220px;
  right: 0;
  height: 64px;
  padding: 0 32px 0 32px;
  border-bottom: solid $border-color 1px;
  display: flex;
  align-items: center;

  &__title {
    font-size: 1em;
    font-weight: normal;
    margin-right: auto;

    &-link {
      text-decoration: none;
      color: $color-gray;

      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }
  }

  &__userInfo {
    display: flex;
    flex-direction: column;
    border-right: solid $border-color 1px;
    padding: 0 0.6em 0 0;
    margin-right: 10px;

    &-name {
      margin-left: auto;
    }

    &-email {
      color: $color-gray;
    }
  }

  &__logout {
    display: flex;
    background-color: $color-light-gray;
    padding: 0.5em;
    border-radius: 4px;

    &__link {
      color: $color-gray;
      text-decoration: none;

      img {
        vertical-align: middle;
        height: auto;
        padding: 0 0.1em 0 0;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}