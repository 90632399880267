.Admin {
  display: flex;
  justify-content: center;
  gap: 1.5em;
  flex-wrap: wrap;

  &__table {
    flex-grow: 1;
    border-bottom: 1px solid $border-color;
  }
}