.tooltip-container {
  position: relative;

  &-vacation {
    cursor: pointer;
  }

  .help-tip {
    position: absolute;
    display: none;
    border-radius: 4px;
    background-color: $color-light-gray;
    border: 1px solid $border-color;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    &-vacation {
      right: 0;
      padding: 1em;
      width: 300px;
      font-size: 0.8em;
    }

    &-token {
      bottom: 100%;
      left: 0;
      padding: 0.8em;
      width: 250px;
    }

  }

  input:hover + .help-tip-token {
    display: block;
  }

  .help-icon-vacation {
    display: inline-block;
    background-color: lighten($color-gray, 20%);
    border-radius: 50%;
    padding: 0.5em 0.8em;
    font-size: 1em;
    font-weight: bold;
    color: $color-white;

    &:hover + .help-tip-vacation {
      display: block;
    }
  }
}

.employee__vacation-request__help {
  &-block {
    margin: 0.9em 0;
    padding-left: 0.9em;
    border-left: 4px solid #ccc;
  }

  &-title {
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  &-note {
    font-size: 1em;
    color: $color-gray
  }
}

