button {
  padding: 0.75em 1.5em;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 1em;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.reset {
    background-color: $color-light-gray;
    color: $color-gray;

    &:hover {
      background-color: darken($color-light-gray, 10%);
    }
  }

  &.other {
    background-color: lighten($color-gray, 20%);
    color: darken($color-gray, 50%);

    &:hover {
      background-color: $color-gray;
    }
  }

  &.submit {
    background-color: $color-pink;
    color: $color-white;

    &:hover {
      background-color: darken($color-pink, 10%);
    }
  }

  &.admin {
    background-color: $color-purple;
    color: $color-white;

    &:hover {
      background-color: darken($color-purple, 10%);
    }
  }
}