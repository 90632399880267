.table {
  font-size: 0.8em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  width: 100%;
  border-spacing: 0 10px;

  th, td {
    padding: 0.5em;
    text-align: center;

    button {
      margin-bottom: 0.2em;
    }

    a {
      text-decoration: none;
      color: $color-active;

      &:hover {
        text-decoration: underline;
      }
    }

    & .--paid {
      color: $color-success;
    }

    & .--unpaid {
      color: $color-error;
    }

    & .--approved {
      color: $color-approved;
    }

    & .--rejected {
      color: $color-rejected;
    }

    & .--updated {
      color: $color-updated;
    }

    & .--cancelled {
      color: $color-cancelled;
    }

    & .--requested {
      color: $color-requested;
    }
  }


  tbody tr:hover {
    background-color: darken($color-light-gray, 1%);
  }

  tbody tr {
    background-color: $color-light-gray;
  }

  tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.Admin__table-table {
  @extend .table;
}

.employee-list__table {
  &-header {
    cursor: pointer;

    &:hover {
      color: $color-active;
    }

    &.ascending::after {
      content: "▲";
    }

    &.descending::after {
      content: "▼";
    }
  }

  &-content {
    @extend .table;
    font-size: 1em;
  }
}