.form {
  border: 1px solid $border-color;
  padding: 1em;
  margin: 0.5em 0;
  border-radius: 12px;

  &__group {
    margin-bottom: 1.5em;
    display: flex;
    flex-direction: column;

    &-label {
      color: $color-gray;
      padding-bottom: 0.5em;
    }

    &-input {
      padding: 0.75em;
      border-radius: 4px;
      border: 1px solid $border-color;
      font-size: 1em;

      &:focus {
        border-color: $color-pink;
        outline: none;
      }
    }

    &-textarea {
      max-width: 90%;
      max-height: 6em;
      min-width: 90%;
      min-height: 6em;

    }
  }

  &__button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1em;

  }
}

.employee__vacation-request {
  &__form {
    @extend .form;
  }
}

.admin__form {
  @extend .form;
}

.employee-tab-upcoming__form {
  display: flex;
  flex-direction: column;
  gap: 0.2em;

  input, button {
    flex-grow: 1;
  }
}