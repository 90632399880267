.employee {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .line{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1em;

    &__subpart{
      display: flex;
      flex-grow: 1;
      gap: 1em;
    }
  }


  &__card {
    width: 175px;
    margin-right: 1em;
  }

  &__remaining-vacation {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 60%;
  }

  &__admin-actions {
    display: flex;
    padding: 1em;
    flex-direction: column;
    justify-content: start;
    gap: 1em;
    align-items: end;
  }

  &__tabs {
    flex-grow: 1;
    margin-right: 2em;
  }

  &__vacation-request {
    margin-right: 2em;

    &__header {
      display: flex;
      justify-content: space-between;
    }
  }

  &__token-update {
    width: 100%;

    &__title {
      margin-bottom: 1em;
    }

    &__form {
      display: flex;
      gap: 1em;
    }

    .help-container {
      display: flex;
      gap: 1em;
    }
  }
}


