.employee__tabs {
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px $border-color;
  margin-bottom: 1em;
  margin-top: 1em;
  min-width: 60%;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .employee__tabs-list {
    list-style: none;
    padding: 0;
    display: flex;
    border-bottom: 1px solid $border-color;
    margin-bottom: 1rem;

    .employee__tab-item {
      margin-right: 1rem;
      padding-bottom: 0.5rem;
      cursor: pointer;

      a {
        text-decoration: none;
        color: $color-black;
        font-weight: lighter;
        padding: 0.5rem 1rem;
        display: block;

        &:hover {
          color: $color-pink;
        }
      }

      &.active {
        a {
          color: $color-pink;
          border-bottom: 2px solid $color-pink;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
