.alert {
  width: 100%;
  margin-bottom: 1em;
  color: $color-white;
  padding: 0.5em;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-success {
    background-color: $color-success;
  }

  &-error {
    background-color: $color-error;
  }

  &__close {
    padding: 0 0.25em 0 0.25em;
    font-size: 1.5em;

    &:hover {
      cursor: pointer;
    }
  }
}

.alert-login {
  position: absolute;
  top: 3%;
  right: 3%;
  background-color: $color-light-gray;
  color: $color-black;
  padding: 0.5em;
  border-radius: 4px;
  display: flex;
  align-items: center;

}