.upcoming {
  display: flex;
  flex-direction: column;
  gap: 1em;

  &__header {
    &__info-message {
      .download-link {
        color: $color-pink;
        text-decoration: none;

        &:hover {
          color: darken($color-pink, 15%);
        }
      }
    }
  }

  &__calendar {
    width: 80%;
    align-self: center;
  }
}
