.nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $color-darkblue;
  padding: 10px;

  &__corner {
    text-align: start;
    margin-bottom: 20px;

    &__logo {
      padding: 1em 0 0 0;
      width: 150px;
    }
  }

  &__tabs {
    &__list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;

      li {
        margin-bottom: 0.5em;

        a {
          display: flex;
          text-decoration: none;
          color: $color-gray;
          font-weight: bold;
          font-family: $font-family-primary;
          padding: 0.5em;
          border-radius: 4px;

          &.current {
            color: $color-white;
            background-color: $color-pink;

            img {
              filter: brightness(200%);
            }
          }

          &.chat {
            &:hover {
            }
          }

          img {
            vertical-align: middle;
            height: auto;
            padding: 0 0.6em 0 0;
          }
        }
      }
    }
  }

  &__logout {
    margin-top: auto;
    margin-bottom: 30px;

    a {
      text-decoration: none;
      color: $color-white;
      font-weight: bold;
      font-family: $font-family-primary;

      &:hover {
        color: $color-active;
      }
    }
  }
}